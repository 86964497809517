<template>
  <div>
    <flixLoader v-if="!loaded"/>
    <div v-else>
      <backBtn :onClick="setBack"><template v-slot:text>{{ $t('message.back') }}</template></backBtn>
      <h3 class="flix-html-h3"><flixIcon id="building" /> {{ organisation.name }}</h3>
      <a href="#" class="flix-html-a" @click.prevent="changeStatus"><status :status="organisation.status" style="margin-right: 2px"/>
      Organisation {{ $t('message.' + organisation.status) }}</a>
      <accounts />
      <h4 class="flix-html-h4" v-if="organisation.admin === variables.user.ID">
        {{ calendarCount }} {{ $tc('message.calendar', calendarCount) }} <span v-if="calendarCount !== 1">wurden</span><span v-else>wurde</span> der Organisation zugeordnet</h4>
      <div class="flix-form-group" v-if="organisation.admin === variables.user.ID">
        <editBtn :onClick="function () { editCalendar = !editCalendar }"><template v-slot:text>{{ $t('message.edit', { name: $tc('message.calendar', calendarCount) }) }}</template></editBtn>
      </div>
      <transition name="flixFadeIn">
        <div class="flix-well" :key="editCalendar" v-if="editCalendar">
          <calendarList :check="JSON.parse(organisation.calendar)" :callback="setCalendar" />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    calendarList () { return import('@/components/permissions/organisations/list/calendarList.vue') },
    accounts () { return import('@/components/permissions/accounts/index.vue') },
    status () { return import('@/components/permissions/organisations/list/status.vue') }
  },
  props: {},
  data () {
    return {
      loaded: false,
      variables: this.$getUserVariables(),
      organisation: false,
      calendarCount: 0,
      editCalendar: false
    }
  },
  computed: {

  },
  mounted () {
    this.getOrganisations()
  },
  methods: {
    setCalendar (ret) {
      this.organisation.calendar = JSON.stringify(Object.values(ret))
      this.calendarCount = JSON.parse(this.organisation.calendar).length
      this.$flix_post({
        url: 'organisation/calendar/set',
        data: {
          user: this.$store.getters.user.md5_id,
          calendar: this.organisation.calendar,
          organisation: this.$route.params.action
        },
        callback: function (ret) { window.console.log(ret) }
      })
    },
    changeStatus () {
      if (this.organisation.status === 'active') {
        this.organisation.status = 'inactive'
      } else {
        this.organisation.status = 'active'
      }
      this.$flix_post({
        url: 'organisation/status/set',
        data: {
          user: this.$store.getters.user.md5_id,
          status: this.organisation.status,
          organisation: this.$route.params.action
        },
        callback: function () { }
      })
    },
    setBack () {
      this.$router.push({ name: 'dashboardPermissions', params: { part: 'organisations', action: 'list' } })
    },
    setOrganisation (ret) {
      this.loaded = true
      this.organisation = ret.filter(function (r) { if (r.ID === this.$route.params.action) { return true } return false }.bind(this))[0]
      this.calendarCount = JSON.parse(this.organisation.calendar).length
    },
    getOrganisations () {
      this.$store.dispatch('getOrganisations', this.setOrganisation)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
